import { RootState } from '../rootReducer';
import { App } from './actions';

export const getAppState = (state: RootState): App => state.app;
export const getActiveTapp = (state: RootState): number => state.app.activeTapp;
export const getActiveSmartClientTapp = (state: RootState): string => state.app.activeSmartClientTapp;
export const getActiveDavidSiteId = (state: RootState): string => state.app.activeDavidSiteId;

export const getAppBottomBarHeight = (state: RootState): number => (state.app.hotCardHeight ?? 0) + (state.app.toolbarHeight ?? 0);
export const getSafeAreas = (state: RootState): { bottom: number } => state.app.safeAreas;
